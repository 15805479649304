#projects {
    padding-top: 50px;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .projet_main-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 25px;
    width: 100%;
    max-width: 1200px;
  }
  
  @media screen and (max-width: 768px) {
    .projet_main-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }
  
  .projet_slideshow {
    grid-column: 1 / 3;
    width: 100%;
    height: 400px;
    background-color: #f0f0f0;
    border-radius: 15px;
    overflow: hidden;
  }
  
  @media screen and (max-width: 768px) {
    .projet_slideshow {
      grid-column: 1 / 2;
      height: 250px;
    }
  }
  
  .projet_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-column: 1 / 3;
    margin-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media screen and (max-width: 768px) {
    .projet_container {
      flex-direction: column; 
      align-items: center;
    }
  }
  
  .projet_title {
    font-size: 32px;
    color: black;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .projet_title {
        width: 90%;
        padding: 10px;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        font-size: 24px;
        text-align: center;
        margin-bottom: 15px;
    }
  }

  .icon-container{
    display: flex;
    gap: 10px;

    @media screen and (max-width: 768px) {
      justify-content: center;}

  }
  
  
  .project-icon {
    height: 50px;
    object-fit: contain;
  }

  .github-logo {
    margin-left: 90px;
    height: 50px;
    object-fit: contain;

    @media screen and (max-width: 768px) {
      margin-left: 0px;}

  }
  
  @media screen and (max-width: 768px) {
    .project_competences {
      font-size: 16px;
    }
  }
  
  .collapse_section {
    grid-column: 1 / 3;
    margin-bottom: 50px;
    margin-top: 50px;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media screen and (max-width: 768px) {
    .collapse_section {
      grid-column: 1 / 2;
    }
  }
  
  .collapse {
    margin-bottom: 10px;
  }
  
  .collapse_title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  @media screen and (max-width: 768px) {
    .collapse_title {
      font-size: 18px;
    }
  }
  
  .collapse_content {
    padding: 15px 0;
    font-size: 16px;
    color: black;
  }
  
  @media screen and (max-width: 768px) {
    .collapse_content {
      font-size: 14px;
    }
  }
  