#contactme {
	width: 50%;
	margin: auto;

	@media screen and (max-width: 768px) {
		width: 90%;	
	}

}
#contactme > * {
	text-align: center;

}
#contactme h2{
	margin-bottom: 20px;
}
#contactme form {
	text-align: left;
	margin-top:30px;
	display: flex;
	flex-direction: column;
}

#contactme input {
	height: 50px;
	font-size: 1.2em;
	border: none;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
}
#contactme label {
	margin: 2em 0 1em 0;
}
#contactme textarea {
	border: none;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
}

input[type="submit"]{
	font-family: 'Syne';
	font-weight: 700;
	color: white;
	background-color: black;
	margin : 2em auto ;
	width: 180px;
	text-align: center;
	border-radius: 60px ;
}
