.myproject_title{
    width: 90%;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
    padding-top: 50px;
    margin: auto;
    font-size: 24px;

}

.project_cards {
    width: 100%;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 50px;
    padding: 50px;
    border-radius: 25px;
    list-style: none;
    box-sizing: border-box;
}

@media screen and (max-width: 767px) {
    .project_cards {
        background-color: white;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
    }
}

.project_cards-card {
    width: 30%;
    height: 340px;
    min-height: 340px;
    border-radius: 10px;
    background-position: center;
    background-blend-mode: multiply;
    cursor: pointer;
    background-repeat: no-repeat;
    
}

.project_cards-card:hover {
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 768px) {
    .project_cards-card {
        width: 100%;
        height: 255px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1340px) {
    .project_cards-card {
        width: 45%;
    }
}

.projects_cards-link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end; 
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.projects_cards-title {
    color: white;
    padding: 10px;
    font-size: 1.5em;
    line-height: 1.2;
    font-weight: bold;
}
