#banner{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

h1{
    Width: 90%;
    MARGIN: auto;
    font-size: 100px;
    font-family: impact, sans-serif;

    @media screen and (max-width: 768px){
        text-align: center;
        font-size: 70px;
      }

}

.title_entrance {
    font-size: 30px;
    width: 90%;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
    padding-top: 50px;
    margin: auto;
}

.desc_entrance {

        width: 90%;
        margin: auto;
        padding-bottom: 30px;
        padding-top: 20px;
        font-weight: 500;
}

.image_BW {
    width: 45%;
    height: auto;
    transform: translateY(-50px);
    @media (max-width: 767.98px){
        display: none;
    }
}

.button{
    padding-bottom: 100px;
    width: 90%;
    margin: auto;
}

.mylink_logo{
    transform: translateY(-1px)}