footer {
    width: 100%;
    height: 20vh;
    background-color: black;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    }

    .footer_logo {
        width: 20%;
        height: auto;
    }
  
    .desc_footer {
      margin: 0;
      color: white;
      padding-top: 1%;
      text-align: center;
    }
  
    @media screen and (max-width: 768px) {
  
      .footer_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .desc footer{
        font-size: 12px;
        line-height: 17.11px;
        text-align: center;
        width: 100px;
      }
    }