.competence{
    width: 90%;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
    padding-top: 50px;
    margin: auto;
    font-size: 24px;
}

.skillsicons {
    margin-top: 10px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    background-color: black;
}

.iconslist {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

@media (max-width: 767.98px){
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px;
    gap:30px 
    }
}





        