.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;}

    .logo_TW {
      display: block;
      width: 10%;
    }

    .header  ul{
      width: 50%;
      display: flex;
      flex-direction: row;
      list-style-type: none;
      text-align: center;
    }

    .header ul li {font-size: 18px;
      font-weight: 500;
      width: 100%;

      @media screen and (max-width: 1024px) {

        font-size: 14px;
      }}

    .header ul li :hover{
      border-bottom: 2px solid black;
    }

    .header ul li a{
      text-decoration: none;
      color:black
    }
    
    @media screen and (max-width: 768px) {

    .header {
      margin-bottom: 150px;

      @media screen and (max-width: 450px) {
      display: flex;
      flex-direction: column;
    }}

      .logo_TW {
        width: 50%;
      }

      .header ul {
        width: 100%;
        margin:0px;
        padding: 0px;
        display: flex;
        flex-wrap:wrap ;
      }

      .header ul li {
        font-size: 15px;
        height: 35px;
        margin:0px;
        width: 50%;

      }
      
    }
  