.collapses_section {
    margin: 50px;
  
    @media screen and (max-width: 768px) {
      margin: 0px;
    }
  }
  
  .collapse {
    margin-bottom: 25px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      margin: 0px;
    }
  }
  
  .collapse .headercollapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border-radius: 7px;
    margin: 0;
    height: 3em;
    padding: 10px;
    background-color: black;
    color: white;
    font-size: 24px;
  
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  
  .collapse .content {
    font-size: 18px;
    border-radius: 7px;
    height: 0;
    opacity: 0;
    transition: all 0.3s ease;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f6f6f6;
  
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .collapse .content.open {
    margin-bottom: 30px;
    font-size: 1.1em;
    height: auto;
    min-height: fit-content;
    padding: 1em;
    opacity: 1;
    transition: all 0.3s ease;
  
    @media screen and (max-width: 768px) {
      min-height: fit-content;
      font-size: 12px;
      margin: 0px;
    }
  }
  