.slideshow_container {
    grid-column: 1/3;
    border-radius: 25px;
    height: 455px;
    width: 100%;
    background-size: cover;
    background-position: center;
    border: 2px solid #000;
  }
  
  @media screen and (max-width: 768px) {
    .slideshow_container {
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 255px;
      height: auto;
      border-radius: 10px;
    }
  }
  
  .slideshow_icons {
    color: black;
    height: 80px;
    width: 80px;
    position: relative;
    top: 40%;
  }
  
  @media screen and (max-width: 768px) {
    .slideshow_icons {
      position: absolute;
      top: auto;
      height: 24px;
      width: 24px;
    }
  
    .slideshow_icons.right {
      right: 7px;
    }
  }
  
  .slideshow_counter {
    color: black;
    position: relative;
    top: 90%;
    left: 42%;
  }
  
  @media screen and (max-width: 768px) {
    .slideshow_counter {
      display: none;
    }
  }
  