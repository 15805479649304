.back-to-home { 
    text-decoration: none;
    color: #000;
    font-size: 50px; 
    display: flex;
    align-items: center; 
    margin: 20px;
  }
  
  .back-to-home:hover {
    color: #3d4e60; 
  }

.fa-beat {
  animation: beat 1s infinite;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

  
