.about_title{ 
   font-size: 24px;
    border-bottom: 2px solid white; 
    padding-bottom: 10px; 
    color: white;
    
}

.desc_about {
  margin-bottom: 50px;
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}


#aboutme{
    display: flex;
    flex-direction: row;
    background-color: black;
    
    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
    }
}

.container{
    display: flex;
    flex-direction: column;
    color: white;
    width: 75%;

    @media screen and (max-width: 768px){
      width: 90%;
      margin: auto;
    }

}



.logo_white {
    width: 25%; /* Ajuste la taille si nécessaire */
    animation: rotateY 2s infinite linear; /* Adjust timing and easing */

    @media screen and (max-width: 768px){
      width: 50%;
      margin: auto;
    }

}

@keyframes rotateY {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
  }